import clsx from 'clsx';

const SkeletonLoader = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx(
        'h-48 w-full animate-pulse rounded-lg bg-gray-100 dark:bg-brand--5',
        className,
      )}
    />
  );
};

export default SkeletonLoader;
