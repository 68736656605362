import { useContractRead } from 'wagmi';
import * as viem from 'viem';
import { erc20ABI } from '../generated';

export function useTokenBalance({
  address,
  tokenAddress,
}: {
  address: `0x${string}` | undefined;
  tokenAddress: `0x${string}` | undefined;
}) {
  return useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [address!],
    enabled: viem.isAddress(address ?? '') && viem.isAddress(tokenAddress ?? ''),
    watch: true,
  });
}
