import useTranslation from 'next-translate/useTranslation';
import * as viem from 'viem';
import { useModalsStore } from '../../stores/modals';
import { formatDateWithTime } from '../../utils/formatDate';
import { useTokenBalance } from '../../utils/hooks/token/useTokenBalance';
import { useRewardsAssets } from '../../utils/useAssets';
import BaseButton from '../base/BaseButton';
import { Reward } from './types';
import RewardPropertySection from './RewardPropertySection';
import ProgressBar from '../base/ProgressBar';
import { WEI_PER_ETHER } from '../../utils/constants';
import { useProtocolLiabilities } from '../../utils/hooks/staking/useProtocolLiabilities';
import { calculatePeriodProgressPercentage } from '../../utils/rewardPeriod/calculatePeriodProgressPercentage';
import { useChargeUpStakingStore } from '../../stores/chargeUpStaking';

interface OngoingPeriodProps {
  reward: Reward;
  adminView: boolean;
  staking: viem.Address;
}

const MoneyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="mb-1 mr-3 inline h-4 w-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
      />
    </svg>
  );
};

const RewardPeriodHasEnded = ({ date }: { date: Date }) => {
  const { t } = useTranslation('admin');
  return (
    <div className="mb-12">
      {t('REWARD_PERIOD_HAS_ENDED', {
        date: formatDateWithTime(date),
      })}
    </div>
  );
};

const RewardPeriodNotFound = () => {
  const { t } = useTranslation('admin');
  return <div className="mb-12">{t('REWARD_PERIOD_NOT_FOUND')}</div>;
};

const OngoingPeriod = ({ staking, reward, adminView = false }: OngoingPeriodProps) => {
  const { t } = useTranslation('admin');

  const openModal = useModalsStore((store) => store.openModal);
  const setSelectedStakingRewardAsset = useChargeUpStakingStore(
    (store) => store.setSelectedStakingRewardAsset,
  );

  const rewardToken = useRewardsAssets().find(
    (asset) => asset.stakingContractAddress === staking,
  );

  const { data: stakingContractBalance } = useTokenBalance({
    tokenAddress: rewardToken?.address,
    address: staking,
  });

  const { data: protocolLiabilities } = useProtocolLiabilities({
    rewardTokenAddress: rewardToken?.address,
  });

  if (+reward.period.startingDate === 0 && +reward.period.endingDate === 0) {
    return <div className="mb-12">{t('REWARDS_PERIOD_NOT_STARTED_YET')}</div>;
  }

  const progressPercentageValue = calculatePeriodProgressPercentage(reward.period);
  const progressPercentage =
    (progressPercentageValue || 0) >= 100 ? 100 : progressPercentageValue;

  const hasPeriodEnded = progressPercentage && progressPercentage >= 100;

  if (hasPeriodEnded && !adminView) {
    return <RewardPeriodHasEnded date={reward.period.endingDate} />;
  }

  if (!rewardToken) {
    return <RewardPeriodNotFound />;
  }

  const amountOwedToAllUsers =
    (reward.amount * viem.parseEther(progressPercentage?.toString() || '0')) /
    BigInt(WEI_PER_ETHER) /
    100n;

  const dateLabelClass = 'my-2 min-w-[120px] whitespace-nowrap';

  return (
    <div className="mb-12">
      <RewardPropertySection title={t('TOTAL_REWARD_VALUE')}>{`${
        viem.formatUnits(reward.amount, rewardToken.decimals) || 0
      } ${reward.currency}`}</RewardPropertySection>

      <RewardPropertySection title={t('TOTAL_AMOUNT_OF_REWARDS_ACCRUED_THIS_PERIOD')}>
        {viem.formatUnits(amountOwedToAllUsers || 0n, rewardToken.decimals)}{' '}
        {reward.currency}
      </RewardPropertySection>

      <RewardPropertySection title={t('CONTRACT_BALANCE')}>
        {viem.formatUnits(stakingContractBalance || 0n, rewardToken.decimals)}{' '}
        {reward.currency}
      </RewardPropertySection>

      {protocolLiabilities !== 0n && adminView && (
        <RewardPropertySection title={t('CURRENT_LIABILITIES_TOWARDS_ALL_STAKERS')}>
          {viem.formatUnits(protocolLiabilities, rewardToken.decimals)} {reward.currency}
        </RewardPropertySection>
      )}

      {!hasPeriodEnded ? (
        <div className="mb-6 flex flex-col font-light sm:flex-row sm:items-center">
          <span className={dateLabelClass}>
            <span className="sm:hidden">{t('START_DATE')}:</span>{' '}
            {formatDateWithTime(reward.period.startingDate)}
          </span>

          <ProgressBar percent={progressPercentage} className="grow sm:mx-2" />

          <span className={dateLabelClass}>
            <span className="sm:hidden">{t('END_DATE')}:</span>{' '}
            {formatDateWithTime(reward.period.endingDate)}
          </span>
        </div>
      ) : (
        <RewardPeriodHasEnded date={reward.period.endingDate} />
      )}

      {adminView && (
        <BaseButton
          size="small"
          className="w-full md:max-w-[260px]"
          onClick={() => {
            setSelectedStakingRewardAsset(rewardToken.symbol);
            openModal('charge-up');
          }}
        >
          <MoneyIcon />

          {t('CHARGE_UP_WITH_USDC')}
        </BaseButton>
      )}
    </div>
  );
};

export default OngoingPeriod;
