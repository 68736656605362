import { Address, useContractRead } from 'wagmi';
import { Reward } from '../../../components/rewards/types';
import { periodStarterABI, stakingABI } from '../generated';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';

export type UseRewardReturnType = { refetch: () => void } & (
  | {
      isLoading: true;
      reward: null;
    }
  | {
      isLoading: false;
      reward: Reward;
    }
);

const useUpcomingReward = ({ staking }: { staking: Address }): UseRewardReturnType => {
  const { periodStarter } = useProtocolContractAddresses();
  const {
    data: nextPeriodRewardsAmount,
    isLoading: isLoadingNextRewardsAmount,
    refetch: refetchNextPeriodRewardsAmount,
  } = useContractRead({
    address: periodStarter,
    abi: periodStarterABI,
    functionName: 'nextPeriodRewardsAmount',
  });
  const {
    data: nextPeriodFinishAt,
    isLoading: isLoadingNextPeriodFinishAt,
    refetch: refetchNextStakingPeriodFinishAt,
  } = useContractRead({
    address: periodStarter,
    abi: periodStarterABI,
    functionName: 'nextStakingPeriodFinishAt',
  });
  const {
    data: currentPeriodfinishAt,
    isLoading: isLoadingCurrentPeriodfinishAt,
    refetch: refetchFinishAt,
  } = useContractRead({
    address: staking,
    abi: stakingABI,
    functionName: 'finishAt',
  });

  const refetch = () => {
    refetchNextPeriodRewardsAmount();
    refetchNextStakingPeriodFinishAt();
    refetchFinishAt();
  };

  if (
    isLoadingNextRewardsAmount ||
    isLoadingNextPeriodFinishAt ||
    isLoadingCurrentPeriodfinishAt
  ) {
    return {
      isLoading: true,
      reward: null,
      refetch,
    };
  }

  return {
    isLoading: false,
    reward: {
      id: crypto.randomUUID(),
      period: {
        startingDate: new Date((Number(currentPeriodfinishAt) || 0) * 1000),
        endingDate: new Date((Number(nextPeriodFinishAt) || 0) * 1000),
      },
      amount: nextPeriodRewardsAmount!,
      currency: 'USDC',
      status: 'upcoming',
    },
    refetch,
  };
};

export default useUpcomingReward;
