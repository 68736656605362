import { PropsWithChildren } from 'react';

interface RewardPropertySectionProps {
  title: string;
}

const RewardPropertySection: React.FC<PropsWithChildren<RewardPropertySectionProps>> = ({
  title,
  children,
}) => {
  const sectionHeadingClass =
    'mb-4 font-light border px-3 py-2 text-sm rounded dark:border-brand--5';
  const sectionValueClass = 'mt-1 block font-bold text-lg overflow-hidden break-words';
  return (
    <h3 className={sectionHeadingClass}>
      {title}: <strong className={sectionValueClass}>{children}</strong>
    </h3>
  );
};

export default RewardPropertySection;
