/* eslint-disable @next/next/no-img-element */
import useTranslation from 'next-translate/useTranslation';
import BaseBox from '../base/BaseBox';
import ConnectWalletButton from '../wallet/ConnectWalletButton';

const WelcomeScreen = () => {
  const { t } = useTranslation('welcome');

  return (
    <div className="relative flex min-h-[60vh] w-full items-center overflow-hidden">
      <img
        className="absolute z-[-1] max-h-full opacity-50 lg:right-[5vw]"
        src="/wallets-logos.png"
        alt=""
      />

      <div className="z-1 w-full md:w-1/2">
        <BaseBox className="bg-white dark:bg-brand--6" title={t('HEY')}>
          <p className="font-light leading-loose">{t('WELCOME_TEXT')}</p>

          <ConnectWalletButton className="connect-wallet-btn mt-8" />
        </BaseBox>
      </div>
    </div>
  );
};

export default WelcomeScreen;
