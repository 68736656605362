import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getWebisteURL } from '../../getWebsiteURL';

async function getProtocolLiabilities({ rewardToken }: { rewardToken: string }) {
  const {
    data: { protocolLiabilities },
  } = await axios<{
    protocolLiabilities: string;
  }>({
    method: 'GET',
    url: `${getWebisteURL()}/api/get-protocol-liabilities?reward_asset_address=${rewardToken}`,
  });

  return BigInt(protocolLiabilities);
}

export function useProtocolLiabilities({
  rewardTokenAddress,
}: {
  rewardTokenAddress: string | undefined;
}) {
  return useQuery({
    queryKey: ['collectedRewards', rewardTokenAddress],
    queryFn: () =>
      getProtocolLiabilities({
        rewardToken: rewardTokenAddress ?? '',
      }),
    refetchInterval: 60_000,
    initialData: 0n,
  });
}
