import clsx from 'clsx';

const ProgressBar = ({
  percent = 0,
  className,
}: {
  percent?: number;
  className?: string;
}) => {
  return (
    <div className={clsx('h-3 rounded-full border border-brand--3', className)}>
      <div className="h-2.5 rounded-full bg-brand--3" style={{ width: `${percent}%` }} />
    </div>
  );
};
export default ProgressBar;
