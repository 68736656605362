import { Address, useContractRead } from 'wagmi';
import { UseRewardReturnType } from './useUpcomingReward';
import { stakingABI } from '../generated';
import { useRewardsAssets } from '../../useAssets';

const useOngoingReward = ({ staking }: { staking: Address }): UseRewardReturnType => {
  const rewardAssets = useRewardsAssets();

  const { data: rewardsTokenAddress, isLoading: isLoadingRewardsToken } = useContractRead(
    {
      address: staking,
      abi: stakingABI,
      functionName: 'rewardsToken',
    },
  );

  const rewardToken = rewardAssets.find(
    (token) => token.address?.toLowerCase() === rewardsTokenAddress?.toLowerCase(),
  );

  const {
    data: currentPeriodRewardsAmount,
    isLoading: isLoadingCurrentRewards,
    refetch: refetchCurrentPeriodRewardsAmount,
  } = useContractRead({
    address: staking,
    abi: stakingABI,
    functionName: 'currentPeriodRewardsAmount',
  });
  const {
    data: duration,
    isLoading: isLoadingDuration,
    refetch: refetchDuration,
  } = useContractRead({
    address: staking,
    abi: stakingABI,
    functionName: 'duration',
  });
  const {
    data: finishAt,
    isLoading: isLoadingFinishAt,
    refetch: refetchFinishAt,
  } = useContractRead({
    address: staking,
    abi: stakingABI,
    functionName: 'finishAt',
  });

  const refetch = () => {
    refetchCurrentPeriodRewardsAmount();
    refetchDuration();
    refetchFinishAt();
  };

  if (
    isLoadingRewardsToken ||
    isLoadingCurrentRewards ||
    isLoadingDuration ||
    isLoadingFinishAt
  ) {
    return {
      isLoading: true,
      reward: null,
      refetch,
    };
  }

  const endingDate = new Date((Number(finishAt) || 0) * 1000);

  return {
    isLoading: false,
    reward: {
      id: crypto.randomUUID(),
      period: {
        startingDate: new Date((Number(finishAt) - Number(duration || 0) || 0) * 1000),
        endingDate,
      },
      amount: currentPeriodRewardsAmount!,
      currency: rewardToken?.symbol as 'USDC' | 'USDC.e',
      status: endingDate > new Date() ? 'ongoing' : 'paid',
    },
    refetch,
  };
};

export default useOngoingReward;
