import { format } from 'date-fns';

export const formatDate = (timestamp: number | Date) => {
  try {
    return format(timestamp, 'dd / MM / yyyy');
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const formatDateWithTime = (timestamp: number | Date) => {
  try {
    return format(timestamp, 'dd / MM / yyyy HH:mm:ss');
  } catch (e) {
    console.log(e);
    return '';
  }
};
