import { Reward } from '../../components/rewards/types';
import { getPeriodLength } from './getPeriodLength';

export const calculatePeriodProgressPercentage = (period: Reward['period']) => {
  const periodLength = getPeriodLength(period);
  const periodDurationTillNow = getPeriodLength({
    startingDate: period.startingDate,
    endingDate: new Date(),
  });

  const progressPercentage = (periodDurationTillNow / periodLength) * 100;

  if (isNaN(progressPercentage)) return undefined;

  if (progressPercentage < 1e-4) return 0;

  return progressPercentage;
};
