import useTranslation from 'next-translate/useTranslation';
import * as viem from 'viem';
import { formatDateWithTime } from '../../utils/formatDate';
import { useRewardsAsset } from '../../utils/useAssets';
import { Reward } from './types';

interface UpcomingPeriodProps {
  reward: Reward;
}

const UpcomingPeriod = ({ reward }: UpcomingPeriodProps) => {
  const { t } = useTranslation('admin');

  const rewardAsset = useRewardsAsset(reward.currency);

  const rewardValue = `${viem.formatUnits(
    reward.amount || 0n,
    rewardAsset?.decimals || 6,
  )} ${reward.currency}`;

  const fromDate =
    reward.period.startingDate.getTime() === 0
      ? '-'
      : formatDateWithTime(reward.period.startingDate);
  const toDate =
    reward.period.endingDate.getTime() === 0
      ? '-'
      : formatDateWithTime(reward.period.endingDate);

  return (
    <div>
      <p className="mb-3">
        {t('TOTAL_REWARD_VALUE')}: <strong>{rewardValue}</strong>
      </p>

      <p>
        <span className="mr-10">
          {t('FROM')}: <strong>{fromDate}</strong>
        </span>

        <br className="xl:hidden" />

        <span>
          {t('TO')}: <strong>{toDate}</strong>
        </span>
      </p>
    </div>
  );
};

export default UpcomingPeriod;
